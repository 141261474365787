import axios from "axios";
import qs from "querystring";
import configs from "./../configs";
import Vue from "vue";
import GenericDAO from "./../generic-dao";

const failed = new Promise(resolve => {
    resolve(false);
});

const authenticate = (username, password) => {
    let dataSend = {
        grant_type: "password",
        client_id: configs.clientId,
        client_secret: configs.clientSecret,
        username,
        password
    };

    return axios
        .post(`${configs.baseUrl}/oauth/token/`, qs.stringify(dataSend), {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
            let usuario = {
                sessionValid: true,
                accessToken: response.data.access_token,
                tokenType: response.data.token_type,
                estabelecimentoAtualUsuario: 0
            };
            let currentUser = JSON.parse(localStorage.getItem('usuario'))
            if (currentUser && currentUser.idxCurrentEstabelecimento){
                usuario.estabelecimentoAtualUsuario = currentUser.idxCurrentEstabelecimento
            }
            localStorage.setItem("usuario", JSON.stringify(usuario));
            return response;
        })
        .catch(err => err.response);
};

const find = (username, persist) => {
    let usuario = JSON.parse(localStorage.getItem("usuario"));
    if (!usuario) return failed;
    else
        return axios
            .get(
                `${configs.baseUrl}/webcli/funcionario/busca/?username=${username}`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: `${usuario.tokenType} ${usuario.accessToken}`
                    }
                }
            )
            .then(response => {
                usuario.id = response.data.id;
                if (persist)
                    localStorage.setItem("usuario", JSON.stringify(usuario));
                return response;
            })
            .catch(err => err.response);
};

const login = (username, password) => {
    return authenticate(username, password).then(response => {
        let usuario = JSON.parse(localStorage.getItem("usuario"));
        if (!response) {
            Vue.$toast.error("Sem conexão com o servidor", { duration: 3000 });
            return false;
        } else if (response.status !== 200) return false;
        else
            return axios
                .get(`${configs.baseUrl}/oauth/userdata/`, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: `${usuario.tokenType} ${usuario.accessToken}`
                    }
                })
                .then(response => {
                    if (response.status != 200) return false;

                    usuario.id = response.data.id;
                    usuario.estabelecimentosGrupo = {
                        medico: response.data.estabelecimentos_medico,
                        administrativo: response.data.estabelecimentos
                    };
                    usuario.estabelecimentos = response.data.estabelecimentos;
                    usuario.idxCurrentEstabelecimento = usuario.estabelecimentoAtualUsuario ? usuario.estabelecimentos[usuario.estabelecimentoAtualUsuario] : 0

                    let cd_cidade = usuario.estabelecimentos[usuario.estabelecimentoAtualUsuario].cd_cidade

                    usuario.opcoes_cidade = response.data.opcoes_cidade;
                    usuario.opcoes_estado = response.data.opcoes_estado;

                    const idCidade = Number(cd_cidade)
                    usuario.idxCidadeSelecionada = usuario.opcoes_cidade?.findIndex(
                        c => c.id === idCidade
                    );

                    usuario.firstName = response.data.first_name;
                    usuario.lastName = response.data.last_name;
                    usuario.username = response.data.username;
                    usuario.grupos = response.data.grupos;
                    usuario.nivel = response.data.nivel;

                    usuario.menu = {};

                    let menus = [];
                    let menusTemp = response.data.menus;
                    configs.menuForcedOrder.forEach(item => {
                        let find = menusTemp.find(
                            menu => menu.ds_html_id == item
                        );
                        if (find) {
                            menus.push(find);
                            menusTemp = menusTemp.filter(
                                menu => menu.ds_html_id != item
                            );
                        }
                    });
                    menus = menus.concat(menusTemp);

                    menus.forEach(menu => {
                        usuario.menu[menu.ds_rota] = {
                            title: menu.ds_label,
                            icon: menu.nm_icone
                        };
                        if (menu.items.length) {
                            usuario.menu[menu.ds_rota].children = [];
                            menu.items.forEach(item => {
                                usuario.menu[item.ds_rota] = {
                                    title: item.ds_label,
                                    icon: item.nm_icone
                                };
                                usuario.menu[menu.ds_rota].children.push(
                                    item.ds_rota
                                );
                            });
                        }
                    });

                    // usuario.menu['tipo-checkup'] = { title: 'Tipo de Checkup', icon: 'jam jam-cogs' }
                    // usuario.menu['checkup'] = { title: 'Checkup', icon: 'jam jam-cogs' }

                    localStorage.setItem("usuario", JSON.stringify(usuario));
                    return JSON.parse(localStorage.getItem("usuario"));
                });
    });
};

const forgotPassword = email => {
    return axios
        .post(`${configs.baseUrl}/webcli/pessoa-fisica/esqueci-minha-senha/`, {
            ds_email: email
        })
        .then(response => response)
        .catch(err => err.response);
};

const changePassword = (ds_password_atual, ds_password1, ds_password2) => {
    let usuario = JSON.parse(localStorage.getItem("usuario"));
    if (!usuario) return failed;
    let dataSend = {
        ds_password_atual: ds_password_atual,
        ds_password1: ds_password1,
        ds_password2: ds_password2
    };
    return axios
        .patch(
            `${configs.baseUrl}/webcli/alterar-senha/`,
            qs.stringify(dataSend),
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `${usuario.tokenType} ${usuario.accessToken}`
                }
            }
        )
        .then(response => response)
        .catch(err => err.response);
};

const logout = () => {
    let usuario = JSON.parse(localStorage.getItem("usuario"));
    if (!usuario) return failed;
    else {
        let dataSend = {
            token: usuario.accessToken,
            client_id: configs.clientId,
            client_secret: configs.clientSecret
        };
        localStorage.clear();
        return axios
            .post(
                `${configs.baseUrl}/oauth/revoke-token/`,
                qs.stringify(dataSend),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            )
            .then(response => response)
            .catch(err => err.response);
    }
};

const resetPassword = (cd_user_id, password) => {
    let usuario = JSON.parse(localStorage.getItem("usuario"));
    if (!usuario) return failed;

    let dataSend = {
        id: cd_user_id,
        password: password
    };
    return axios
        .patch(
            `${configs.baseUrl}/webcli/resetar-senha/`,
            qs.stringify(dataSend),
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `${usuario.tokenType} ${usuario.accessToken}`
                }
            }
        )
        .then(response => response)
        .catch(err => err.response);
};

const findUserBySafe = params => GenericDAO.find(`/webcli/usuario/`, params);
// procura o usuario pelo estabelecimento e pelo tipo de caixa. endpoint usado em na tela de cadastro de caixa

export default {
    login,
    logout,
    forgotPassword,
    changePassword,
    find,
    resetPassword,
    findUserBySafe
};
