import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/unidades-medclub/`, params, { ignorarEstabelecimento: true })
const findAllSetor = (params) => GenericDAO.find(`/medclub/unidades-setor/`, params, { ignorarEstabelecimento: true })
const findAllSala = (params) => GenericDAO.find(`/medclub/unidades-sala/`, params, { ignorarEstabelecimento: true })

const find = (id) => GenericDAO.find(`/medclub/unidades-medclub/${ id }/`)
const findSetor = (id) => GenericDAO.find(`/medclub/unidades-setor/${ id }/`)
const findSala = (id) => GenericDAO.find(`/medclub/unidades-sala/${ id }/`)

const remove = (id) => GenericDAO.remove(`/medclub/unidades-medclub/${ id }/`)
const removeSetor = (id) => GenericDAO.remove(`/medclub/unidades-setor/${ id }/`)
const removeSala = (id) => GenericDAO.remove(`/medclub/unidades-sala/${ id }/`)

const save = (dataSend) => GenericDAO.save(`/medclub/unidades-medclub/`, dataSend, { ignorarEstabelecimento: true })
const saveSetor = (dataSend) => GenericDAO.save(`/medclub/unidades-setor/`, dataSend, { ignorarEstabelecimento: true })
const saveSala = (dataSend) => GenericDAO.save(`/medclub/unidades-sala/`, dataSend, { ignorarEstabelecimento: true })

export default {
    find,
    findAll,
    remove,
    save,
    findAllSetor,
    findAllSala,
    saveSetor,
    saveSala,
    removeSetor,
    removeSala,
    findSetor,
    findSala,
}