import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/pacote/`, params, { ignorarEstabelecimento:true })
const find = (id) => GenericDAO.find(`/medclub/pacote/${ id }/`, null, { ignorarEstabelecimento: true})
const remove = (id) => GenericDAO.remove(`/medclub/pacote/${ id }/`, null, null, { ignorarEstabelecimento: true })
const save = (dataSend) => GenericDAO.save(`/medclub/pacote/`, dataSend, { ignorarEstabelecimento:true })
const blockPacote = (id, dataSend) => GenericDAO.save(`/medclub/pacote/${ id }/bloquear/`, dataSend, { ignorarEstabelecimento: true })
const unblockPacote = (id) => GenericDAO.save(`/medclub/pacote/${ id }/desbloquear/`, null, { ignorarEstabelecimento:true })
const buscarConsultas = (params) => GenericDAO.find('/medclub/pacote/tabela-preco/', params, { ignorarEstabelecimento:true })
const vendaPacotes = (params) => GenericDAO.find(`/medclub/pacote/venda-pacote/`, params, { ignorarEstabelecimento:true })
const datasPacotes = (id) => GenericDAO.find(`/medclub/pacote/${id}/datas`, null, { ignorarEstabelecimento: true })
const reservar = (id, dataSend) => GenericDAO.save(`/medclub/pacote/${id}/reservar/`, dataSend, { ignorarEstabelecimento: true })

export default {
    find,
    findAll,
    remove,
    save,
    blockPacote,
    unblockPacote,
    buscarConsultas,
    vendaPacotes,
    datasPacotes,
    reservar
}
