import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/webcli/grupo/`, params, {ignorarEstabelecimento:true})
const find = (id) => GenericDAO.find(`/webcli/grupo/${ id }/`)
const remove = (id) => GenericDAO.remove(`/webcli/grupo/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/webcli/grupo/`, dataSend)
const mapa = (params) => GenericDAO.find(`/medclub/mapa-grupo/`, params)
const salvarMapa = (dataSend) => GenericDAO.save(`/medclub/mapa-grupo/`, dataSend)

export default {
    find,
    findAll,
    remove,
    save,
    mapa,
    salvarMapa
}