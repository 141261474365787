import axios from 'axios'
import configs from '../configs'
import GenericDAO from './../generic-dao'

const failed = new Promise(resolve => { resolve(false) })

const getHistorico = (idPaciente) => GenericDAO.find(`/webcli/informacao-paciente/${ idPaciente }/`)
const getComorbidadesPaciente = (idGuia, idPaciente ) => GenericDAO.find(`/medclub/resposta-comorbidade/guia/${ idGuia }/paciente/${ idPaciente }/`)
const getComorbidades = () => GenericDAO.find(`/appuse/comorbidade/`)
const atualizaComorbidadesPaciente = (idGuia, idPaciente, params ) => GenericDAO.save(`/medclub/resposta-comorbidade/guia/${ idGuia }/paciente/${ idPaciente }/`, params, { ignorarEstabelecimento:false })
//Endpoint utilizado para acessar o token do médico
const getDadosEspecialista = () => GenericDAO.find(`/medclub/integracao-memed/dados-especialista/`)
const getPrescricaoMemed = () => GenericDAO.find(`/webcli/anexo/prescricao-memed`)
const savePrescricaoMemed = (params) => GenericDAO.save(`/webcli/anexo/prescricao-memed/`, params)
const savePrescricaoNexoData = (params) => GenericDAO.save(`/webcli/prescricao-nexodata/`, params)
const getDadosNexoData = (id_horario) => GenericDAO.find(`/webcli/dados-prescricao-nexodata/${id_horario}/`)

const getAnexos = (idGuiaEletronica, params) => {
    if (! params) params = {}
    params.cd_guia_eletronica = idGuiaEletronica
    return GenericDAO.find(`/webcli/anexo/`, params)
}
const getAnexoArquivo = (cdAnexo, width) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios({
        url: `${ configs.baseUrl }/webcli/anexo/${ cdAnexo }/?arquivo=true&h=0&w=${ width }`,
        method: 'GET',
        headers: { 'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` },
        responseType: 'blob'
    })
    .then(response => response)
    .catch(configs.errorHandler)
}
const saveAnexo = (idGuiaEletronica, tipo, text) => GenericDAO.save(`/webcli/anexo/`,
    { cd_guia_eletronica: idGuiaEletronica, ie_tipo_anexo: tipo, ds_anexo: text })

const removeAnexo = (id, motivo='') => GenericDAO.remove(`/webcli/anexo/${ id }/`,{ds_motivo: motivo})
const assinarDocumentos = (params) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))

    if(params.ie_assinar == undefined) params['ie_assinar'] = false
    let urlEndpoint = ( params.ie_assinar ) ? "webcli/enviar-assinar-documento" : "webcli/enviar-sem-assinar-documento";
    //params['ds_destinatario'] = "umeduardo@gmail.com"
    if( params.ie_assinar == false ){
        delete params['username'];
        delete params['password'];
    }

    if (!usuario) return failed
    else return axios.post(
        `${ configs.baseUrl }/${ urlEndpoint }/`, params, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const getRelatorio = (params) => GenericDAO.find(`/webcli/relatorios/especialistas/`, params)
const getRelatorioGraficos = (params) => GenericDAO.find(`/webcli/relatorios/especialistas-graficos/`, params)
const imprimirReceita =(id, params) => GenericDAO.find(`/webcli/anexo/${ id }/imprimir-receita/`, params)

export default {
    getHistorico,
    savePrescricaoNexoData,
    getAnexos,
    getAnexoArquivo,
    saveAnexo,
    removeAnexo,
    assinarDocumentos,
    getRelatorio,
    getRelatorioGraficos,
    getComorbidadesPaciente,
    getComorbidades,
    atualizaComorbidadesPaciente,
    getDadosEspecialista,
    getPrescricaoMemed,
    getDadosNexoData,
    savePrescricaoMemed,
	imprimirReceita
}
