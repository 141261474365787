import axios from 'axios'
import configs from '../configs'

const failed = new Promise(resolve => { resolve(false) })

const findByHorario = (cd_horario) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    return axios.get(
        `${ configs.baseUrl }/medclub/horario/laudo/${cd_horario}`, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const findByCpf = (cpf) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed

    let params = { cpf: cpf }
    return axios.get(
        `${ configs.baseUrl }/medclub/horario/laudo/`, params, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

export default {
    findByCpf,
    findByHorario
}