import axios from 'axios'
import configs from '../configs'
import GenericDAO from './../generic-dao'

const failed = new Promise(resolve => { resolve(false) })

const findAll = (params) => GenericDAO.find(`/medclub/caixas/`, params)
const find = (id) => GenericDAO.find(`/medclub/caixas/${ id }/`)
const remove = (id) => GenericDAO.remove(`/medclub/caixas/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/caixas/`, dataSend)
const getCaixaGeral = (params) => GenericDAO.find(`/medclub/caixas/caixa-geral/`, params)
const getCaixaGeralOperacional = (params) => GenericDAO.find(`/medclub/caixas/caixa-geral/operacional/`, params)
const getAgendamentos = (params, cancelToken) => GenericDAO.find(`/appuse/caixa/agendamentos/`, params, { ignorarEstabelecimento: true }, cancelToken)
const getAgendamentosNovo = (params, cancelToken) => GenericDAO.find(`/appuse/caixa/agendamentos/novo/`, params, { ignorarEstabelecimento: true }, cancelToken)
const getFormasPagamento = (params) => GenericDAO.find(`/medclub/formas-pagamento/`, params, { ignorarEstabelecimento: true })
const saveCaixaFormasPagamento = (formData) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed

    return axios.patch(
        `${ configs.baseUrl }/medclub/caixas/formas-pagamento/`, formData,
        { headers: {'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}
const registrarPagamento = (params) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.post(
        `${ configs.baseUrl }/medclub/caixa/`, params, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}
const pagamentoTEF = (dataSend) => GenericDAO.save(`/medclub/transacao-cartao/`, dataSend)
const pagamentoTEFSituacao = (cd_transacao_cartao) => GenericDAO.find(`/medclub/transacao-cartao/${cd_transacao_cartao}/situacao/`, {})
const removePagamentoTEF = (cd_transacao_cartao) => GenericDAO.save(`/medclub/transacao-cartao/${cd_transacao_cartao}/cancelar/`)
const findAllMovimentos = (params, cancelToken) => GenericDAO.find(`/medclub/transacoes-financeiras/movimentos-caixa/`, params, {}, cancelToken)
const deleteMovimento = (id,params) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.post(
        `${ configs.baseUrl }/medclub/transacoes-financeiras/cancelar-movimento/${ id }`, params, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}


const checkCaixaAtivo = (dataSend) => GenericDAO.find(`/medclub/caixa/caixa-ativo/`, dataSend)
const findAllTransacoes = (params, cancelToken) => GenericDAO.find(`/medclub/transacoes-financeiras/`, params, {}, cancelToken)
const findAllMovimentoCaixa = (params) => GenericDAO.find(`/medclub/transacoes-financeiras/movimento-caixa/`, params)
const findTransacao = (id) => GenericDAO.find(`/medclub/transacoes-financeiras/${ id }/`)
const findTransacaoRevenda = (id) => GenericDAO.find(`/medclub/revenda/transacoes-financeiras/${ id }/`)
const findTransacaoPDF = (id) => GenericDAO.find(`/medclub/transacoes-financeiras/${ id }/`, { html: true }, { ignorarEstabelecimento: true })
const updateTransacaoPagante = (id, cd_pagante) => GenericDAO.save(`/medclub/transacoes-financeiras/`, { id, cd_pagante }, { ignorarEstabelecimento: true })
const updateIndicadorPagante = (id, cd_indicador) => GenericDAO.save(`/medclub/transacoes-financeiras/indicador/`, { id, cd_indicador})

const getCaixasFechados = () => GenericDAO.find(`/medclub/caixa/caixas-fechados/`)
const abrirCaixa = (dataSend) => GenericDAO.save(`/medclub/caixa/abertura/`, dataSend)
const getResumoCaixa = (cd_saldo) => GenericDAO.find(`/medclub/caixa/resumo/${cd_saldo ? cd_saldo+'/' : ''}`)
const fecharCaixaOperacional = (dataSend) => GenericDAO.save(`/medclub/caixa/fechar/`, dataSend)
const fecharCaixaSupervisao = (dataSend) => GenericDAO.save(`/medclub/caixa/fechar-supervisao/`, dataSend)
const estorno = (idTransacao, dataSend = {}) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.post(
        `${ configs.baseUrl }/medclub/transacoes-financeiras/estorno/${ idTransacao }/`, dataSend, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const findAllFechamentoSupervisao = (params) => GenericDAO.find(`/medclub/caixas/historico/`, params, { ignorarEstabelecimento: true })
const findAllFechamentoOperacional = (params) => GenericDAO.find(`/medclub/caixas/historico/operacional/`, params, { ignorarEstabelecimento: true })
const findFechamentoSupervisao = (id, params) => GenericDAO.find(`/medclub/caixas/historico/${id}/`, params, { ignorarEstabelecimento: true })
const findFechamentoOperacional = (id, params) => GenericDAO.find(`/medclub/caixas/historico/operacional/${id}/`, params, { ignorarEstabelecimento: true })
const findFechamentoFundoFixo = (id, params) => GenericDAO.find(`/medclub/caixas/historico/troco/${id}/`, params, { ignorarEstabelecimento: true })
const getCaixaResumo = (id, params) => GenericDAO.find(`/medclub/caixas/resumo/${ id }/`, params, { ignorarEstabelecimento: true })
const imprimirGuiasLote = (cd_transacao_financeira) =>
    GenericDAO.find(`/webcli/guia-eletronica/horario-agendamento/imprimir/`, { cd_transacao_financeira }, { ignorarEstabelecimento: true })
const imprimirGuias = (params) =>
    GenericDAO.find(`/webcli/guia-eletronica/horario-agendamento/imprimir/`, params, { ignorarEstabelecimento: true })


const cancelarGuias = (idGuias, creditar = false) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed

    const action = creditar ? 'cancelar-com-creditos' : 'cancelar'
    return axios.post(
        `${ configs.baseUrl }/medclub/guias-eletronicas/${action}/`, idGuias,
        { headers: { 'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const cancelarGuia = (idGuia, creditar = false) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed

    const action = creditar ? 'cancelar-com-creditos' : 'cancelar'
    return axios.patch(
        `${ configs.baseUrl }/medclub/guias-eletronicas/${action}/${ idGuia }/`, {},
        { headers: { 'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const desfazerCancelamento = (dataSend) => GenericDAO.save(`/medclub/guia-eletronica/desfazer-cancelamento/`, dataSend)

const corrigirMovimento = (idTransacao, dataSend) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.patch(
        `${ configs.baseUrl }/medclub/transacoes-financeiras/${ idTransacao }/corrigir-movimentos/`, dataSend, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}
const cancelarMovimento = (idMovimento, dataSend) => GenericDAO.save(`/medclub/transacoes-financeiras/cancelar-movimento/${ idMovimento }/`, dataSend)
const incluirMovimento = (idTransacao, dataSend) => GenericDAO.save(`/medclub/transacoes-financeiras/incluir-movimento/${ idTransacao }/`, dataSend)
const emitirNF = (idTransacao, ieEmitido = true) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.post(
        `${ configs.baseUrl }/medclub/transacoes-financeiras/nf/${ idTransacao }/`, { ie_emitido_nf: ieEmitido }, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const saveMotivoEstorno = (dataSend) => GenericDAO.save('/medclub/motivo-estorno/', dataSend, { ignorarEstabelecimento: true })
const findAllMotivoEstorno = (params) => GenericDAO.find('/medclub/motivo-estorno/', params, { ignorarEstabelecimento: true })
const salvarEstorno = (dataSend) => GenericDAO.save('/medclub/solicitacao-estorno/', dataSend)
const solicitacaoEstornoOperacional = (dataSend) => GenericDAO.save('/medclub/solicitacao-estorno/aprovar/operacional/', dataSend)
const salvarEstornoOperacional = (dataSend) => GenericDAO.save('/medclub/solicitacao-estorno/operacional/', dataSend)
const findAllEstorno = (params) => GenericDAO.find('/medclub/solicitacao-estorno/', params)
const findEstornoPdf = (id) => GenericDAO.find(`/medclub/solicitacao-estorno/${ id }/`, { html: true })
const findJuros = (dataSend) => GenericDAO.save(`/medclub/solicitacao-estorno/calcular-juros/`, dataSend)
const realizarPagamentos = (id) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.patch(
        `${ configs.baseUrl }/medclub/solicitacao-estorno/aprovar/${ id }/`, {}, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const removeEstorno = (id) => GenericDAO.remove(`/medclub/solicitacao-estorno/${ id }/`, { ignorarEstabelecimento: true })
const removeEstornoOperacional = (id) => GenericDAO.remove(`/medclub/solicitacao-estorno/operacional/${ id }/`, { ignorarEstabelecimento: true })

const anexarComprovanteEstorno = (dataSend) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed

    let formData = {
        aq_anexo: dataSend.aq_anexo
    }

    if (dataSend.aq_anexo) {
        formData = new FormData()
        formData.append('aq_anexo', dataSend.aq_anexo)
        formData.append('cd_solicitacao_estorno', dataSend.cd_solicitacao_estorno)
    }
    return axios.post(
        `${ configs.baseUrl }/medclub/solicitacao-estorno/comprovante/`, formData,
        { headers: {'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
    )
        .then(response => response)
        .catch(configs.errorHandler)
}

const removerComprovanteEstorno = (id) => GenericDAO.remove(`/medclub/solicitacao-estorno/comprovante/${ id }`, { ignorarEstabelecimento: true })

const findAllResgateCreditos = (params) => GenericDAO.find('/medclub/resgate-creditos/', params)
const salvarResgateCreditos = (dataSend) => GenericDAO.save('/medclub/resgate-creditos/', dataSend)
const removerComprovanteResgate = (id) => GenericDAO.remove(`/medclub/resgate-creditos/${ id }/comprovante/`)
const anexarComprovanteResgate = (id, comprovante) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed

    let formData = new FormData()
    formData.append('aq_comprovante', comprovante)

    return axios.patch(
        `${ configs.baseUrl }/medclub/resgate-creditos/${ id }/comprovante/`, formData,
        { headers: {'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
    )
        .then(response => response)
        .catch(configs.errorHandler)
}
const enviarEmail = (id, dataSend) => GenericDAO.save(`/medclub/transacoes-financeiras/enviar-email/${ id }/`, dataSend)
const abrirCaixaOperacional = (dataSend) => GenericDAO.save(`/medclub/caixa/abrir/`, dataSend)
const reabrirCaixa = (cd_saldo) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.patch(
        `${ configs.baseUrl }/medclub/caixa/reabrir/${cd_saldo ? cd_saldo+'/' : ''}`, {},
        { headers: { 'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}
const realizarSangria = (dataSend) => GenericDAO.save(`/medclub/caixas/realizar-sangria/`, dataSend)
const atualizarFundoFixo = (dataSend) => GenericDAO.save(`/medclub/caixa/atualizar-troco/`, dataSend)
const caixaDepositoBanco = (id, nr_valor) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
        if (!usuario) return failed
        else return axios.patch(
            `${ configs.baseUrl }/medclub/caixa/supervisao/${ id }/transferir/`, { nr_valor },
            { headers: { 'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
        )
        .then(response => response)
        .catch(configs.errorHandler)
}
const anexarComprovanteMovimento= (id, comprovante) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed

    if(comprovante.aq_deposito === null) {
        return axios.patch(
            `${ configs.baseUrl }/medclub/caixas/registrar-comprovante/${ id }/`, comprovante,
            { headers: {'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
        )
            .then(response => response)
            .catch(configs.errorHandler)
    }

    let formData = new FormData()
    if (comprovante.aq_deposito !== undefined) formData.append('aq_deposito', comprovante.aq_deposito)
    formData.append('cd_comprovante', comprovante.cd_comprovante)

    return axios.patch(
        `${ configs.baseUrl }/medclub/caixas/registrar-comprovante/${ id }/`, formData,
        { headers: {'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
    )
        .then(response => response)
        .catch(configs.errorHandler)
}
const quebraCaixa = (dataSend) => GenericDAO.save(`/medclub/caixa/quebra-caixa/`, dataSend)
const cancelarQuebra = (dataSend) => GenericDAO.save(`/medclub/caixa/cancelar-quebra/`, dataSend)

const findConfigTroco = (id) => GenericDAO.find(`/medclub/config-troco/${ id }/`)
const findAllConfigTroco = (params) => GenericDAO.find('/medclub/config-troco/', params)
const saveConfigTroco = (dataSend) => GenericDAO.save(`/medclub/config-troco/`, dataSend)
const removeConfigTroco = (id) => GenericDAO.remove(`/medclub/config-troco/${ id }/`)

const getBandeirasOperadoras = (params) => GenericDAO.find(`/medclub/operadora/bandeira/`, params)
const getjuros = (params) => GenericDAO.find(`/medclub/taxa-credito/vigente/`, params)
export default {
    find,
    findAll,
    remove,
    save,
    getCaixaGeral,
    getCaixaGeralOperacional,
    getAgendamentos,
    getAgendamentosNovo,
    getFormasPagamento,
    saveCaixaFormasPagamento,
    registrarPagamento,
    pagamentoTEF,
    pagamentoTEFSituacao,
    removePagamentoTEF,
    checkCaixaAtivo,
    findAllTransacoes,
    findTransacao,
    findTransacaoRevenda,
    findTransacaoPDF,
    getCaixasFechados,
    abrirCaixa,
    getResumoCaixa,
    findAllMovimentos,
    fecharCaixaOperacional,
    findAllFechamentoSupervisao,
    findFechamentoSupervisao,
    findFechamentoOperacional,
    realizarPagamentos,
    getCaixaResumo,
    imprimirGuiasLote,
    imprimirGuias,
    findAllMovimentoCaixa,
    deleteMovimento,
    estorno,
    cancelarMovimento,
    corrigirMovimento,
    incluirMovimento,
    emitirNF,
    cancelarGuia,
    cancelarGuias,
    updateTransacaoPagante,
    updateIndicadorPagante,
    saveMotivoEstorno,
    findAllMotivoEstorno,
    salvarEstorno,
    salvarEstornoOperacional,
    solicitacaoEstornoOperacional,
    findAllEstorno,
    findEstornoPdf,
    findJuros,
    removeEstorno,
    removeEstornoOperacional,
    anexarComprovanteEstorno,
    removerComprovanteEstorno,
    findAllResgateCreditos,
    salvarResgateCreditos,
    anexarComprovanteResgate,
    removerComprovanteResgate,
	enviarEmail,
    anexarComprovanteMovimento,
    realizarSangria,
    reabrirCaixa,
    fecharCaixaSupervisao,
    findAllFechamentoOperacional,
    findFechamentoFundoFixo,
    abrirCaixaOperacional,
    caixaDepositoBanco,
    atualizarFundoFixo,
    quebraCaixa,
    cancelarQuebra,
    findConfigTroco,
    findAllConfigTroco,
    saveConfigTroco,
    removeConfigTroco,
    getBandeirasOperadoras,
    getjuros,
    desfazerCancelamento,
}
