import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/pagamentos/`, params, { ignorarEstabelecimento: true })
const find = (id) => GenericDAO.find(`/pagamentos/${ id }/`, null, { ignorarEstabelecimento: true })
const remove = (id) => GenericDAO.remove(`/pagamentos/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/pagamentos/`, dataSend, { ignorarEstabelecimento: true })

export default {
    find,
    findAll,
    remove,
    save
}