import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/menu/`, params)
const find = (id) => GenericDAO.find(`/medclub/menu/${ id }/`)
const remove = (id) => GenericDAO.remove(`/medclub/menu/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/menu/`, dataSend)

export default {
    find,
    findAll,
    remove,
    save
}