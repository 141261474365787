import axios from 'axios'
import configs from '../configs'
import GenericDAO from './../generic-dao'

const failed = new Promise(resolve => { resolve(false) })

const find = (id) => GenericDAO.find(`/webcli/funcionario/${ id }/`)
const findAll = (params) => GenericDAO.find(`/webcli/funcionario/`, params)
const findAllByUnidade = params => GenericDAO.find(`/webcli/usuario/`, params)
const save = (dataSend) => GenericDAO.save(`/webcli/funcionario/`, dataSend)
const remove = (id) => GenericDAO.remove(`/webcli/funcionario/${ id }/`)
const findAllGrupos = (params) => GenericDAO.find(`/webcli/grupos/`, params)
const findAllEstabelecimentos = () => GenericDAO.find(`/webcli/funcionario/estabelecimentos/`)

const addToGrupo = (idFuncionario, idGrupo) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else {
        let dataSend = {
            cd_estabelecimento: usuario.estabelecimentos[usuario.idxCurrentEstabelecimento].id,
            cd_funcionario: idFuncionario,
            cd_grupo: idGrupo
        }
        return axios.post(`${ configs.baseUrl }/webcli/funcionario/grupos/`, dataSend,
            { headers: {
                'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
            } }
        )
        .then(response => response)
        .catch(configs.errorHandler)
    }
}

export default {
    find,
    save,
    remove,
    findAll,
    findAllByUnidade,
    findAllGrupos,
    findAllEstabelecimentos,
    addToGrupo
}