import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/debito-credito/`, params)
const find = (id) => GenericDAO.find(`/medclub/debito-credito/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/debito-credito/`, dataSend, { ignorarEstabelecimento: true })

export default {
    find,
    findAll,
    save,
}
