import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/webcli/endpoint/`, params, {ignorarEstabelecimento:true})
const find = (id) => GenericDAO.find(`/webcli/endpoint/${ id }/`)
const remove = (id) => GenericDAO.remove(`/webcli/endpoint/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/webcli/endpoint/`, dataSend)

export default {
    find,
    findAll,
    remove,
    save
}