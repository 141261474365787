import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/feriado/`, params, { ignorarEstabelecimento:false })
const find = (id) => GenericDAO.find(`/medclub/feriado/${ id }/`)
const remove = (id) => GenericDAO.remove(`/medclub/feriado/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/feriado/`, dataSend, { ignorarEstabelecimento:false })

export default {
    find,
    findAll,
    remove,
    save
}