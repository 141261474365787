import GenericDAO from './../generic-dao'
import axios from 'axios'
import configs from '../configs'

const failed = new Promise(resolve => { resolve(false) })

const findAll = (params) => GenericDAO.find(`/webcli/agenda/bloqueio/`, params)
const find = (id) => GenericDAO.find(`/webcli/agenda/bloqueio/${ id }/`)
const remove = (id) => GenericDAO.remove(`/webcli/agenda/bloqueio/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/webcli/agenda/bloqueio/`, dataSend)

const bloquearAgenda = (dataSend) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    return axios.post(
        `${ configs.baseUrl }/webcli/agenda/bloqueio/`, dataSend, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

export default {
    find,
    findAll,
    remove,
    save,
    bloquearAgenda
}