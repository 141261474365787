import axios from 'axios'
import configs from './../configs'
import GenericDAO from './../generic-dao'

const failed = new Promise(resolve => { resolve(false) })

const create = (dataSend) => {
	let usuario = JSON.parse(localStorage.getItem('usuario'))
	if (!usuario) return failed
	else {
        dataSend.cd_estabelecimento = usuario.estabelecimentos[usuario.idxCurrentEstabelecimento].id
		return axios.post(
			`${ configs.baseUrl }/webcli/gerar-relatorio/`, dataSend, { headers: {
				'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
			} }
		)
		.then(response => response)
		.catch(err => {
			return ([401]).includes(err.response.status) ?
				configs.errorHandler(err) :
				err.response
		})
	}
}

const createGenerico = (dataSend) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'));
    if (!usuario) return failed;

    let config = { headers: { Authorization: `${ usuario.tokenType } ${ usuario.accessToken }` } };
    if (dataSend.ie_tipo_relatorio === 'xlsx') config.responseType = 'blob';

    return axios.post(`${ configs.baseUrl }/medclub/relatorios/`, dataSend, config)
        .then(response => response)
        .catch(err => err.response.status === 401 ? configs.errorHandler(err) : err.response)
}

const getList = () => {
	let usuario = JSON.parse(localStorage.getItem('usuario'))
	if (!usuario) return failed
	else return axios.get(
		`${ configs.baseUrl }/webcli/relatorio/`, { headers: {
			'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
		} }
	)
	.then(response => response)
	.catch(configs.errorHandler)
}

const getFilter = (params) => GenericDAO.find(`/medclub/relatorios/`, params, {ignorarEstabelecimento:true})
const emitirRelatorio = (params) => GenericDAO.save(`/medclub/relatorios/`, params, {ignorarEstabelecimento:true})
const getRelatoriosAtendimento = () => GenericDAO.find('/webcli/relatorio-atendimento/')

const getRelatorioPagamento = (cdPagamento, format) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed

    let config = { headers: { Authorization: `${ usuario.tokenType } ${ usuario.accessToken }` } };
    let params = { cd_pagamento: cdPagamento, [format]: true }
    if (format === 'xlsx') config.responseType = 'blob'

    let url = `${ configs.baseUrl }/appuse/relatorios/pagamentos/?${ new URLSearchParams(params).toString() }`
    return axios.get(url, config).then(response => response).catch(configs.errorHandler)
}

export default { create, getFilter, getList, getRelatoriosAtendimento, getRelatorioPagamento, createGenerico, emitirRelatorio }
