import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/webcli/estabelecimento/`, params)
const findAllClean = (params, configs) => GenericDAO.find(`/webcli/estabelecimento-limpo/`, params, configs)
const find = (id) => GenericDAO.find(`/webcli/estabelecimento/${ id }/`)
const remove = (id) => GenericDAO.remove(`/webcli/estabelecimento/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/webcli/estabelecimento/`, dataSend, {formDataIgnore: true})

const addEspecialidade = (cdEspecialidade, cdEstabelecimento) => GenericDAO.save(`/webcli/especialidade-estabelecimento/`, {
    cd_especialidade: cdEspecialidade, cd_estabelecimento: cdEstabelecimento
}, { ignorarEstabelecimento: true })

const removeEspecialidade = (id) => GenericDAO.remove(`/webcli/especialidade-estabelecimento/${ id }/`)

const addGrupoProcedimento = (cdGrupoProcedimento, cdEstabelecimento) => GenericDAO.save(`/medclub/grupo-procedimento/estabelecimento/`, {
    cd_grupo_procedimento: cdGrupoProcedimento, cd_estabelecimento: cdEstabelecimento
}, { ignorarEstabelecimento: true })

const removeGrupoProcedimento = (id) => GenericDAO.remove(`/medclub/grupo-procedimento/estabelecimento/${ id }/`)

/** 
 * Filtros de Estabelecimento:
 *  ds_busca: nm_fantasia, nm_razao_social ou nr_cnpj. Ex: Medclub; Medclub ltda; 123456789 
 *  cd_especialidades_list: lista de ids dos estabelecimentos separados por vírgula. Ex: 1,76,123,43;
 *  cd_cidade: id da cidade selecionada no login do usuario
 * 
 * Necessário passar a propriedade 'especialistas' com o valor 'true' para listar especialistas
 *  nm_especialista: nome do especialista
 *  cd_estabelecimento: lista de especialistas de um estabelecimento
 *  ref_conselho: informações do conselho
*/
const byEspecialidades = (params) => GenericDAO.find(`/webcli/estabelecimento/locais/`, params, { ignorarEstabelecimento: true})
const corpoClinico = (params) => GenericDAO.find(`/webcli/estabelecimento/locais/`, { especialistas: true, ...params }, { ignorarEstabelecimento: true})

export default {
    find,
    findAll,
	findAllClean,
    byEspecialidades,
    corpoClinico,
    remove,
    save,
    addEspecialidade,
    removeEspecialidade,
    addGrupoProcedimento,
    removeGrupoProcedimento
}
