import GenericDAO from '../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/centro-lucro/`, params)
const find = (id) => GenericDAO.find(`/medclub/centro-lucro/${ id }/`)
const remove = (id) => GenericDAO.remove(`/medclub/centro-lucro/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/centro-lucro/`, dataSend)

export default {
    findAll,
    find,
    remove,
    save,
}
