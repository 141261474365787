import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/plano-contas/`, params, { ignorarEstabelecimento: true })
const find = (id) => GenericDAO.find(`/medclub/plano-contas/${ id }/`, null, { ignorarEstabelecimento: true })
const remove = (id) => GenericDAO.remove(`/medclub/plano-contas/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/plano-contas/`, dataSend, { ignorarEstabelecimento: true })
const gerarCodigo = (dataSend) => GenericDAO.save(`/medclub/plano-contas/gerar-codigo/`, dataSend, { ignorarEstabelecimento: true })
export default {
    find,
    findAll,
    remove,
    save,
    gerarCodigo
}