import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/checkup/`, params, { ignorarEstabelecimento: true })
const find = (id) => GenericDAO.find(`/medclub/checkup/${ id }/`)
const remove = (id) => GenericDAO.remove(`/medclub/checkup/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/checkup/`, dataSend)

const findAllCompras = (params) => GenericDAO.find(`/medclub/checkups-agendados/`, params, { ignorarEstabelecimento: true })

const findAllTipoCheckup = (params) => GenericDAO.find(`/medclub/tipo-checkup/`, params)
const findTipoCheckup = (id) => GenericDAO.find(`/medclub/tipo-checkup/${ id }/`)
const removeTipoCheckup = (id) => GenericDAO.remove(`/medclub/tipo-checkup/${ id }/`)
const saveTipoCheckup = (dataSend) => GenericDAO.save(`/medclub/tipo-checkup/`, dataSend)

export default {
    findAll,
    findAllCompras,
    find,
    remove,
    save,
    findAllTipoCheckup,
    findTipoCheckup,
    removeTipoCheckup,
    saveTipoCheckup
}