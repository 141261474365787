import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/transferencias/`, params)
const find = (id) => GenericDAO.find(`/medclub/transferencias/${id}/`)
const remove = (id) => GenericDAO.remove(`/medclub/transferencias/${id}/`)
const save = (dataSend) => GenericDAO.save(`/medclub/transferencias/`, dataSend)

export default {
    findAll,
    find,
    remove,
    save,
}