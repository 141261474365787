import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/procedimento-estabelecimento/`, params, { ignorarEstabelecimento:true })
const findAllByCity = (params, cancelToken) => GenericDAO.find(
    `/medclub/procedimento-estabelecimento/`, params,
    { ignorarEstabelecimento: true, usarCidade: true }, cancelToken
)
const findAllByPrice = (ids) => GenericDAO.find(`/medclub/procedimento-estabelecimento/filtro-procedimento/?list_estabelecimentos=${ids}&`)
const find = (id) => GenericDAO.find(`/medclub/procedimento-estabelecimento/${ id }/`)
const remove = (id) => GenericDAO.remove(`/medclub/procedimento-estabelecimento/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/procedimento-estabelecimento/`, dataSend, { ignorarEstabelecimento:true })
const getProcedimentosCredenciado = (params) => GenericDAO.find(`/webcli/procedimento-estabelecimento/`, params)
const saveProcedimentosCredenciado = (dataSend) => GenericDAO.save(`/webcli/procedimento-estabelecimento/`, dataSend)
const saveStatus = (dataSend) => GenericDAO.save("/medclub/procedimento-estabelecimento/atualiza-status/", dataSend)

const adicionarMedEmpresa = (dataSend) => GenericDAO.save(`/medclub/solus/rede-credenciada/credenciar-procedimentos/`, dataSend)
const removerMedEmpresa = (dataSend) => GenericDAO.save(`/medclub/solus/rede-credenciada/descredenciar-procedimentos/`, dataSend)


export default {
    find,
    findAll,
    remove,
    save,
    findAllByCity,
    findAllByPrice,
    getProcedimentosCredenciado,
    saveProcedimentosCredenciado,
	saveStatus,
    adicionarMedEmpresa,
    removerMedEmpresa
}
