import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/grupo-procedimento/`, params)
const find = (id) => GenericDAO.find(`/medclub/grupo-procedimento/${ id }/`)
const remove = (id) => GenericDAO.remove(`/medclub/grupo-procedimento/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/grupo-procedimento/`, dataSend)

export default {
    find,
    findAll,
    remove,
    save
}