import GenericDAO from './../generic-dao'
import axios from "axios"
import configs from "@/middleware/configs"

const failed = new Promise(resolve => { resolve(false) })

const findAll = (params) => GenericDAO.find(`/medclub/orcamento-cirurgia/`, params, { ignorarEstabelecimento: true })
const find = (id) => GenericDAO.find(`/medclub/orcamento-cirurgia/${ id }/`, { ignorarEstabelecimento: true })
const remove = (id) => GenericDAO.remove(`/medclub/orcamento-cirurgia/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/orcamento-cirurgia/`, dataSend, { ignorarEstabelecimento: true })
const imprimir = (id, params) => GenericDAO.find(`/medclub/orcamento-cirurgia/${ id }/`, params)

const autorizar = (params) => GenericDAO.save(`/medclub/orcamento-cirurgia/autorizar/`, params, {ignorarEstabelecimento: true})
const desautorizar = (id) => GenericDAO.save(`/medclub/orcamento-cirurgia/desautorizar/`, {id:id}, {ignorarEstabelecimento: true})

const alterarStatus = (id) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    return axios.patch(`${ configs.baseUrl }/medclub/orcamento-cirurgia/${ id }/alterar-status/`, {}, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } })
        .then(response => response)
        .catch(configs.errorHandler)
}

const findAllValid = (params) => GenericDAO.find('/medclub/orcamento-cirurgia/confirmacao-pagamento/', params)
const findToValidate = (id) => GenericDAO.find(`/medclub/orcamento-cirurgia/confirmacao-pagamento/${ id }/`)

const validar = (params) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed

    return axios.patch(`${ configs.baseUrl }/medclub/orcamento-cirurgia/confirmacao-pagamento/${ params['id'] }/confirmar/`, params, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } })
        .then(response => response)
        .catch(configs.errorHandler)
}

const cancelar = (id, dataSend) => GenericDAO.save(
    `/medclub/orcamento-cirurgia/${ id }/cancelar/`,
    dataSend, { ignorarEstabelecimento: true }
)

const cancelarPagamento = (id, dataSend) => GenericDAO.save(
    `/medclub/orcamento-cirurgia/confirmacao-pagamento/${ id }/cancelar/`,
    dataSend, { ignorarEstabelecimento: true }
)

const findAllMovimentacao = (id) => GenericDAO.find(
    `/medclub/orcamento-cirurgia/${ id }/movimentacoes/`
)

const orcamentoSituacao = () => GenericDAO.find('/medclub/orcamento-situacoes/')

export default {
    find,
    findAll,
    remove,
    save,
    imprimir,
    alterarStatus,
    findAllValid,
    findToValidate,
    validar,
    cancelar,
    cancelarPagamento,
    findAllMovimentacao,
    orcamentoSituacao,
    autorizar,
    desautorizar
}
