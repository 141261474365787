import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/webcli/mapa-ocupacional/`, params, {ignorarEstabelecimento:true})
const findSalas = (params) => GenericDAO.find(`/medclub/unidades-sala/`, params)
const AttMapa = () => GenericDAO.save(`/webcli/mapa-ocupacional/`, {} )
const findSetores = (params) => GenericDAO.find(`/medclub/unidades-setor/`, params)

// const find = (id) => GenericDAO.find(`/unidades-medclub/${ id }/`)
// const remove = (id) => GenericDAO.remove(`/unidades-medclub/${ id }/`)
// const save = (dataSend) => GenericDAO.save(`/unidades-medclub/`, dataSend)

export default {
    // find,
    // remove,
    // save,
    findAll,
    findSalas,
    AttMapa,
    findSetores
}