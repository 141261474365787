import GenericDAO from './../generic-dao'

const findAll = () => GenericDAO.find(`/medclub/perguntas-frequentes/`)
const edit = (dataSend) => GenericDAO.save(`/medclub/perguntas-frequentes/`, dataSend)
const remove = (cd_pergunta) => GenericDAO.remove(`/medclub/perguntas-frequentes/${cd_pergunta}/`)
const save = (dataSend) => GenericDAO.save(`/medclub/perguntas-frequentes/`, dataSend)
const findAllCategorias = (params) => GenericDAO.find('/medclub/perguntas-categoria/', params)
const saveCategoria = (dataSend) => GenericDAO.save('/medclub/perguntas-categoria/', dataSend)


export default {
    findAll,
    edit,
    save,
    remove,
    findAllCategorias,
    saveCategoria,
}
