import GenericDAO from "./../generic-dao";

const findAll = params => GenericDAO.find(`/medclub/agencia-bancaria/`, params);
const find = id => GenericDAO.find(`/medclub/agencia-bancaria/${id}/`);
const remove = id => GenericDAO.remove(`/medclub/agencia-bancaria//${id}/`);
const save = dataSend =>
    GenericDAO.save(`/medclub/agencia-bancaria/`, dataSend, {
        ignorarEstabelecimento: true
    });

export default {
    find,
    findAll,
    save,
    remove
};
