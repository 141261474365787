import GenericDAO from './../generic-dao'

const getUFs = () => GenericDAO.find(`/global/estado/`)
const getMunicipiosByUF = (idUF) => GenericDAO.find(`/global/cidade/`, { ds_uf: idUF })
const getMunicipiosEstadoId = (idUF) => GenericDAO.find(`/global/cidade/`, { cd_estado: idUF })
const getCEP = (cep) => GenericDAO.find(`/global/busca-cep/`, { cep }, { ignorarEstabelecimento: true })
const getCEPCidade = (dataSend) => GenericDAO.find(`/global/busca-cep-cidade/`, dataSend, { ignorarEstabelecimento: true })

export default {
    getUFs,
    getMunicipiosByUF,
    getMunicipiosEstadoId,
    getCEP,
    getCEPCidade
}