import axios from 'axios'
import configs from '../configs'
import GenericDAO from './../generic-dao'

const failed = new Promise(resolve => { resolve(false) })

const _find = (pathUrl, params, cancelToken) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return GenericDAO.find(`${ pathUrl }${ usuario.estabelecimentos[usuario.idxCurrentEstabelecimento].id }/`, params, {}, cancelToken)
}

const findAll = (params) => _find(`/webcli/agendamentos/estabelecimento/`, params)
const findAllTelemedicina = (params) => GenericDAO.find(`/webcli/agendamentos/painel-consulta-video/`, params)
const findAllByEstabelecimento = (params, cancelToken) => _find(`/webcli/agendamentos-lista/estabelecimento/`, params, cancelToken)
const getInfoGuiaByHorario = (id) => GenericDAO.find(`/webcli/agendamentos-lista/get-info-guia/${id}/`)
const findLogTelemedicina = (id) => GenericDAO.find(`/webcli/agendamentos/painel-consulta-video/${id}/`)
const findAllByMedico = (params) => _find(`/webcli/agendamentos/especialista/estabelecimento/`, params)
const find = (id) => GenericDAO.find(`/webcli/agendamento/${ id }/`)    // mudar endpoint para inserir endereço do paciente

const atualizaStatus = (id, statusChamada) => GenericDAO.save(`/webcli/agendamento/`, {id:id, ie_status_chamada:statusChamada})

const getEspecialistas = () => GenericDAO.find(`/webcli/especialista/`)
const getProcedimentos = (params) => _find(`/webcli/procedimentos/estabelecimento/`, params)
const getEspecialidades = () => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return GenericDAO.find(`/webcli/estabelecimento/` +
        `${ usuario.estabelecimentos[usuario.idxCurrentEstabelecimento].id }/especialidades/`)
}

const getAllEspecialidades = (params, configs) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return GenericDAO.find(`/webcli/especialidade/`, params, configs)
}

const imprimirGuia = (idAgendamento) => GenericDAO.find(`/webcli/guia-eletronica/horario-agendamento/${ idAgendamento }/imprimir/`, null, { ignorarEstabelecimento: true })

const executarGuia = (idAgendamento, hash) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.patch(
        `${ configs.baseUrl }/webcli/guia-eletronica/horario-agendamento/${ idAgendamento }/`,
        { ds_rash: hash } , { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const executarGuiaMedico = (idAgendamento) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.patch(
        `${ configs.baseUrl }/webcli/guia-eletronica-medico/horario-agendamento/${ idAgendamento }/`,
        { /* no data to send */ }, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const reservarPacote = (cd_pacote, dataSend) => GenericDAO.save(`/medclub/pacote/${cd_pacote}/reservar/`, dataSend,  { ignorarEstabelecimento: true })
// const removerPacote = (cd_pacote, dataSend) => GenericDAO.save(`/medclub/pacote/${cd_pacote}/reservar/`, dataSend,  { ignorarEstabelecimento: true })

const finalizarGuiaMedico = (idAgendamento) => GenericDAO.save(`/webcli/guia-eletronica-medico/horario-agendamento/${ idAgendamento }/finalizar/`)

const removerPacote = (dataSend) => {
    const id = dataSend.id
    delete dataSend.id
    if (!dataSend) dataSend = {}
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.patch(
        `${ configs.baseUrl }/medclub/pacote/${id}/reservar/`,
        dataSend, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const transferirHorario = (idAgendamento, dataSend, force) => {
    if (!dataSend) dataSend = {}
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.patch(
        `${ configs.baseUrl }/webcli/agendamento/${ idAgendamento }/transferir${ force ? '-forcado' : ''}/`,
        dataSend, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const alterarStatusIntegracaoHorario = (idHorario, data) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.patch(
        `${ configs.baseUrl }/webcli/agendamento/integracao/${ idHorario }/`, data, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const getEstabelecimentos = (params, configs) => {
	if (!configs) configs = { ignorarEstabelecimento: true }
	return GenericDAO.find(`/medclub/agendamento/estabelecimentos/`, params, configs)
}
const getEstabelecimentosProcedimentos = (params, configs) => {
	if (!configs) configs = { ignorarEstabelecimento: true }
	return GenericDAO.find(`/medclub/agendamento/procedimentos/estabelecimentos/`, params, configs)
}
const getEstabelecimentosLaboratoriais = (params, configs) => {
	if (!configs) configs = { ignorarEstabelecimento: true }
	return GenericDAO.find(`/medclub/agendamento/estabelecimentos/procedimentos/`, params, configs)
}
const getProcedimentosCadastro = (params, configs) => {
    if (!configs) configs = { ignorarEstabelecimento: true }
    return GenericDAO.find(`/medclub/agendamento/procedimentos/`, params, configs)
}
const getEspecialistasCadastro = (params) => GenericDAO.find(`/medclub/agendamento/especialistas/`, params, { ignorarEstabelecimento: true })
const getEspecialidadesCadastro = (params) => GenericDAO.find(`/medclub/agendamento/especialidades/`, params, { ignorarEstabelecimento: true })
const getDatasCadastro = (params) => GenericDAO.find(`/medclub/agendamento/datas/`, params, { ignorarEstabelecimento: true })
const getDatasAgendaLL= (params) => GenericDAO.find(`/medclub/agendamento/datas/limitada/`, params, { ignorarEstabelecimento: true })
const getHorariosCadastro = (params) => GenericDAO.find(`/medclub/agendamento/horarios/`, params, { ignorarEstabelecimento: true })
const encaixarHorario = (dataSend) => GenericDAO.save(`/medclub/encaixe/`, dataSend)

const reservarHorario = (params) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.patch(
        `${ configs.baseUrl }/medclub/reservar-horario/${ params.cd_horario_agendamento }/`,  params,
        { headers: { 'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const getAtendimento = (id) => GenericDAO.find(`/medclub/atendimento/${ id }/`)
const getAtendimentos = (params) => GenericDAO.find(`/medclub/atendimento/`, params)
const origemAtendimentoFindAll = (params) => GenericDAO.find(`/medclub/origem-atendimento/`, params)
const origemAtendimentoFindAllClean = (params) => GenericDAO.find(`/medclub/origem-atendimento-limpo/`, params)
const origemAtendimentoSave = (dataSend) => GenericDAO.save(`/medclub/origem-atendimento/`, dataSend)
const criarAtendimento = (dataSend) => GenericDAO.save(`/medclub/atendimento/`, dataSend)
const finalizarAtendimento = (cdAtendimento) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.patch(
        `${ configs.baseUrl }/medclub/atendimento/${ cdAtendimento }/finalizar/`,  {},
        { headers: { 'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const imprimeAtendimento = (cdAtendimento) => GenericDAO.find(`/medclub/imprimir-atendimento/${ cdAtendimento }/`)

const getTiposCheckup = (params, configs) => {
	if (!configs) configs = { ignorarEstabelecimento: true }
	return GenericDAO.find(`/medclub/checkup-agendamento/`, params, configs)
}
const getTipoCheckupById = (id, configs) => {
	if (!configs) configs = { ignorarEstabelecimento: true }
	return GenericDAO.find(`/medclub/checkup-agendamento/${ id }/`, null, configs)
}
const reservarHorarioCheckup = (params) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.patch(
        `${ configs.baseUrl }/medclub/reservar-horario-checkup/`, params,
        { headers: { 'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const checkin = (id, desfazer) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'));
    if (!usuario) return failed;
    else return axios.patch(
        `${ configs.baseUrl }/webcli/agendamento/${id}/checkin/`, { desfazer },
        { headers: { 'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
    ).then(response => response).catch(configs.errorHandler);
}

const checkout = (id, desfazer) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'));
    if (!usuario) return failed;
    else return axios.patch(
        `${ configs.baseUrl }/webcli/agendamento/${id}/checkout/`, { desfazer },
        { headers: { 'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
    ).then(response => response).catch(configs.errorHandler);
}

const alterarPrioridade = (id, dataSend) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.patch(
        `${ configs.baseUrl }/webcli/agendamento/${id}/alterar-prioridade/`, dataSend,
        { headers: { 'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } }
    )
        .then(response => response)
        .catch(configs.errorHandler)
}

const comprovanteAgendamento = (horarioId) => GenericDAO.find(`/webcli/agendamento/${ horarioId }/comprovante-agendamento/`)

export default {
    find,
    findAll,
    findAllTelemedicina,
    findLogTelemedicina,
    findAllByMedico,
    getDatasCadastro,
    getDatasAgendaLL,
    getEspecialidades,
    getEspecialidadesCadastro,
    getEspecialistas,
    getEspecialistasCadastro,
    getEstabelecimentosProcedimentos,
    getEstabelecimentos,
    getHorariosCadastro,
    getProcedimentos,
    getProcedimentosCadastro,
    executarGuia,
    imprimirGuia,
    transferirHorario,
    executarGuiaMedico,
    finalizarGuiaMedico,
    reservarHorario,
    getAllEspecialidades,
    encaixarHorario,
    atualizaStatus,
    criarAtendimento,
    finalizarAtendimento,
    getAtendimento,
    getAtendimentos,
    origemAtendimentoFindAll,
    origemAtendimentoFindAllClean,
    origemAtendimentoSave,
    imprimeAtendimento,
    alterarStatusIntegracaoHorario,
    getTiposCheckup,
    getTipoCheckupById,
    reservarHorarioCheckup,
    findAllByEstabelecimento,
    getInfoGuiaByHorario,
    checkin,
    checkout,
    alterarPrioridade,
    comprovanteAgendamento,
    getEstabelecimentosLaboratoriais,
    reservarPacote,
    removerPacote
}
