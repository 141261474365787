import GenericDAO from "./../generic-dao";

const save_termo_avaliacao_anestesica = dataSend =>
    GenericDAO.save(`/appuse/gerar-termo-avaliacao-anestesica/`, dataSend, {
        ignorarEstabelecimento: true
    });

const save_termo_consentimento_anestesica = dataSend =>
    GenericDAO.save(`/appuse/gerar-termo-consentimento-atendimento/`, dataSend, {
        ignorarEstabelecimento: true
    });
    
export default {
    save_termo_avaliacao_anestesica,
    save_termo_consentimento_anestesica
};
