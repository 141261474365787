import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/gestor/cids/`, params, {ignorarEstabelecimento:true})
const delet = (id_horario, id) => GenericDAO.remove(`/webcli/horario-agendamento/${ id_horario }/cid/${ id }/`)
const find = (id) => GenericDAO.find(`/webcli/horario-agendamento/${ id }/cid/`)
const remove = (id) => GenericDAO.remove(`/gestor/cids/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/webcli/horario-agendamento/cid/`, dataSend)

export default {
    find,
    findAll,
    delet,
    remove,
    save
}