import axios from 'axios'
import configs from './../configs'
import GenericDAO from './../generic-dao'

const failed = new Promise(resolve => { resolve(false) })

const find = (id) => GenericDAO.find(`/webcli/agenda/${ id }/`)
const findAll = (params) => GenericDAO.find(`/webcli/agenda/`, params)
const save = (dataSend) => GenericDAO.save(`/webcli/agenda/`, dataSend)
const remove = (id) => GenericDAO.remove(`/webcli/agenda/${ id }/`)

const findAllPacote = (params) => GenericDAO.find(`/webcli/agenda/`, params, { ignorarEstabelecimento: true })

const findByEstabelecimento = (cdEstabelecimento, params) => {
    if (! params) params = {}
    params.cd_estabelecimento = cdEstabelecimento
    return GenericDAO.find(`/webcli/agenda/`, params, { ignorarEstabelecimento: true })
}

const findByEspecialista = (cdEspecialista) => GenericDAO.find(`/webcli/especialista/${cdEspecialista}/agenda/`)

const getDatasDisponiveis = (idAgenda, params) => GenericDAO.find(`/webcli/agenda/${idAgenda}/data/`, params)
const getHorariosDisponiveis = (idAgenda, params) => GenericDAO.find(`/webcli/agenda/${idAgenda}/horario/`, params)
const getAtendimentos = (idAgenda) => GenericDAO.find(`/webcli/agenda/${ idAgenda }/dias-atendimento/`)

const getDatasRetorno = (idHorario, params) => GenericDAO.find(`/webcli/agendamento/${idHorario}/datas-retorno/`, params)
const getHorariosRetorno = (idHorario, params) => GenericDAO.find(`/webcli/agendamento/${idHorario}/horarios-retorno/`, params)
const agendarRetorno = (idHorario, dataSend, force) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed

    const path = `/webcli/agendamento/${idHorario}/agendar-retorno${ force ? '-forcado' : ''}/`
    return axios.patch(
        `${ configs.baseUrl }${path}`, dataSend, { headers: {
                'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
            } }
    )
        .then(response => response)
        .catch(configs.errorHandler)
}

const getEspecialistasAgendados = (params = {}) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else {
        let cdEstabelecimento = params.cd_estabelecimento
        if( params.cd_estabelecimento == undefined )
            cdEstabelecimento = usuario.estabelecimentos[usuario.idxCurrentEstabelecimento].id

        return GenericDAO.find(`/webcli/estabelecimento/${ cdEstabelecimento }/especialistas/`, params)
    }
}

const getProcedimentosByTipoAgenda = (ieTipoAgenda, ativos = false) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else {
        let cdEstabelecimento = usuario.estabelecimentos[usuario.idxCurrentEstabelecimento].id
        let data = { ie_tipo_procedimento: ieTipoAgenda }
        if(ativos){
            data['ativos'] = true
        }
        return GenericDAO.find(`/webcli/procedimentos/estabelecimento/${ cdEstabelecimento }/`, data)
    }
}

const saveAtendimento = (idAgenda, dataSend) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed

    if (dataSend.id) return axios.patch(
            `${ configs.baseUrl }/webcli/agenda/${ idAgenda }/dias-atendimento/${ dataSend.id }/`, dataSend, { headers: {
                'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
            } }
        )
        .then(response => response)
        .catch(configs.errorHandler)
    else return axios.post(
            `${ configs.baseUrl }/webcli/agenda/${ idAgenda }/dias-atendimento/`, dataSend, { headers: {
                'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
            } }
        )
        .then(response => response)
        .catch(configs.errorHandler)
}

const alterarStatusAgenda = (idAgenda) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.patch(
        `${ configs.baseUrl }/webcli/agenda/${ idAgenda }/ativar/`, {}, { headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const removeAtendimento = (idAgenda, idAtendimento) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.delete(
        `${ configs.baseUrl }/webcli/agenda/${ idAgenda }/dias-atendimento/${ idAtendimento }/`, { headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const addEspecialidade = (idAgenda, idEspecialidade) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else {
        let dataSend = {
            cd_agenda: idAgenda,
            cd_especialidade: idEspecialidade,
            nr_valor: 99
        }
        return axios.post(
            `${ configs.baseUrl }/webcli/agenda/${ idAgenda  }/agenda-especialidade/`, dataSend, { headers: {
                'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
            } }
        )
        .then(response => response)
        .catch(configs.errorHandler)
    }
}

const removeEspecialidade = (idAgenda, idAgendaEspecialidade) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.delete(
        `${ configs.baseUrl }/webcli/agenda/${ idAgenda }/agenda-especialidade/${ idAgendaEspecialidade }`, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const getEspecialidadesByTipoAgenda = (ieTipoAgenda) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else {
        let cdEstabelecimento = usuario.estabelecimentos[usuario.idxCurrentEstabelecimento].id
        return GenericDAO.find(`/webcli/estabelecimento/${ cdEstabelecimento }/especialidades/`, { ie_tipo: ieTipoAgenda })
    }
}

const getCalendario = (idAgenda, mes, ano) => GenericDAO.find(`/medclub/agenda/${ idAgenda }/calendario/`, { mes, ano }, { ignorarEstabelecimento: true })

const possibilitarRetorno = (cd_horario) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    return axios.patch(`${ configs.baseUrl }/medclub/agenda/possibilitar-retorno/${ cd_horario }/`, {},
        { headers: {'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` } })
        .then(response => response)
        .catch(configs.errorHandler)
}

export default {
    find,
    findAll,
    findByEstabelecimento,
    findByEspecialista,
    save,
    remove,
    getDatasDisponiveis,
    getHorariosDisponiveis,
    getDatasRetorno,
    getHorariosRetorno,
    agendarRetorno,
    getEspecialistasAgendados,
    getAtendimentos,
    saveAtendimento,
    removeAtendimento,
    getProcedimentosByTipoAgenda,
    getEspecialidadesByTipoAgenda,
    addEspecialidade,
    removeEspecialidade,
    alterarStatusAgenda,
    getCalendario,
    possibilitarRetorno,
    findAllPacote
}
