import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/operadora/`, params)
const find = (id) => GenericDAO.find(`/medclub/operadora/${ id }/`)
const remove = (id) => GenericDAO.remove(`/medclub/operadora/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/operadora/`, dataSend)

export default {
    find,
    findAll,
    remove,
    save,
}
