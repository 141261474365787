import axios from 'axios'
import configs from '../configs'
import GenericDAO from './../generic-dao'

const failed = new Promise(resolve => { resolve(false) })

//Atendimento
const getAtendimento = (id) => GenericDAO.find(`/medclub/atendimento-med-empresa/${ id }/`, null, { ignorarEstabelecimento: true })
const getAtendimentos = (params) => GenericDAO.find(`/medclub/atendimento-med-empresa/`, params)
const criarAtendimento = (dataSend) => GenericDAO.save(`/medclub/atendimento-med-empresa/`, dataSend)

//Empresas
const getEmpresas = (params) => GenericDAO.find(`/webcli/med-empresa/`, params)
const getCorrentista = (params) => GenericDAO.find(`/webcli/med-empresa/correntistas/`, params)

//Credenciamento
const credenciamentoEstabelecimento = (dataSend) => GenericDAO.save(`/medclub/solus/rede-credenciada/estabelecimento/`, dataSend)

//Gestão de pedidos
const cancelarProcedimentos = (dataSend) => {
    if (!dataSend) dataSend = {}
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    return axios.patch(
        `${ configs.baseUrl }/medclub/liberar-horarios-med-empresa/`,
        dataSend, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

// Cancelamento de um pedido gerado
const cancelarPedidoGerado = (id, dataSend) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    return axios.patch(
        `${ configs.baseUrl }/medclub/atendimento/${id}/cancelar/`,
        dataSend, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

//especialistas
const finAllEsp = (params) => GenericDAO.find(`/medclub/solus/rede-credenciada/especialista/`, params)
// const saveEsp = (dataSend) => GenericDAO.save(`/medclub/solus/rede-credenciada/especialista/`, dataSend)
const saveEsp = (dataSend) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    return axios.patch(`${ configs.baseUrl }/medclub/solus/rede-credenciada/especialista/`, dataSend, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } })
        .then(response => response)
        .catch(configs.errorHandler)
}

//Paciente
const findAllPacientes = (params) => GenericDAO.find(`/medclub/solus/paciente/`, params)
const findFamilia = (id, params) => GenericDAO.find(`/medclub/solus/familia-paciente/${id}/`, params)


export default {
    getAtendimento,
    getAtendimentos,
    criarAtendimento,
    findAllPacientes,
    findFamilia,
    credenciamentoEstabelecimento,
    cancelarProcedimentos,
    finAllEsp,
    saveEsp,
    getEmpresas,
    getCorrentista,
    cancelarPedidoGerado
}