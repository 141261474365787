import GenericDAO from './../generic-dao'

const findAll = (params, cancelToken) => GenericDAO.find(`/webcli/tag-area-atuacao/`, params, {}, cancelToken)
const findAllEstabelecimento = (params, configs) => GenericDAO.find(`/webcli/tag-area-atuacao/estabelecimento/`, params, configs)
const find = (id) => GenericDAO.find(`/webcli/tag-area-atuacao/${ id }/`)
const remove = (id) => GenericDAO.remove(`/webcli/tag-area-atuacao/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/webcli/tag-area-atuacao/`, dataSend)
const findByEstabelecimento = (params) => GenericDAO.find('/webcli/tag-area-atuacao/estabelecimento/', params)

export default {
    find,
    findAll,
    remove,
    findByEstabelecimento,
	findAllEstabelecimento,
    save
}
