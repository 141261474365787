import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/bancos/`, params)
const find = (id) => GenericDAO.find(`/bancos/${ id }/`)
const remove = (id) => GenericDAO.remove(`/bancos/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/bancos/`, dataSend)

export default {
    find,
    findAll,
    remove,
    save,
}