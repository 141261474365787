import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/procedimento/`, params)
const findAllEstabelecimentos = (params) => GenericDAO.find(`/medclub/procedimento/`, params, { ignorarEstabelecimento: true })
const findAllClean = (params, cancelToken) => GenericDAO.find(`/medclub/procedimento-limpo/`, params, {}, cancelToken)
const find = (id) => GenericDAO.find(`/medclub/procedimento/${ id }/`)
const remove = (id) => GenericDAO.remove(`/medclub/procedimento/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/procedimento/`, dataSend)
const getTipoProcedimentos = () => GenericDAO.find(`/medclub/tipo-procedimento/`)
const getGrupoProcedimentos = () => GenericDAO.find(`/medclub/grupo-procedimento/`)

export default {
    find,
    findAll,
	findAllClean,
    findAllEstabelecimentos,
    remove,
    save,
    getTipoProcedimentos,
    getGrupoProcedimentos
}
