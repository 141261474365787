import GenericDAO from './../generic-dao'

//const failed = new Promise(resolve => { resolve(false) })

const findAll = (params) => GenericDAO.find(`/medclub/protocolo/`, params)
const find = (id) => GenericDAO.find(`/medclub/protocolo/${ id }/`)
const remove = (id) => GenericDAO.remove(`/medclub/protocolo/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/protocolo/`, dataSend)
const getEspecialidades = () => GenericDAO.find(`/webcli/especialidade/`, { ignorarEstabelecimento: true })

export default {
    find,
    findAll,
    remove,
    getEspecialidades,
    save
}
