import GenericDAO from './../generic-dao'
import axios from "axios"
import configs from "@/middleware/configs"

const failed = new Promise(resolve => { resolve(false) })

const findAll = (params) => GenericDAO.find(`/medclub/cirurgia/`, params, { ignorarEstabelecimento: true })
const find = (id) => GenericDAO.find(`/medclub/cirurgia/${ id }/`, null, { ignorarEstabelecimento: true })
const remove = (id) => GenericDAO.remove(`/medclub/cirurgia/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/cirurgia/`, dataSend, { ignorarEstabelecimento: true })
const alterarStatus = (id, dataSend) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    return axios.patch(`${ configs.baseUrl }/medclub/cirurgia/${ id }/alterar-status/`, dataSend, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } })
        .then(response => response)
        .catch(configs.errorHandler)
}

export default {
    find,
    findAll,
    remove,
    save,
    alterarStatus
}
