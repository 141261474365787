import GenericDAO from '../generic-dao'
import axios from "axios"
import configs from "@/middleware/configs"

const failed = new Promise(resolve => { resolve(false) })


const findAll = (params) => GenericDAO.find(`/medclub/dominios-valores/`, params)
const find = (ds_mnemonico, params) => GenericDAO.find(`/medclub/dominios/${ds_mnemonico}/valores/`, params)
const save = (ie_valor, ds_mnemonico, dataSend) => GenericDAO.save(`/medclub/dominios/${ds_mnemonico}/valores/${ie_valor}/`, dataSend, { ignorarEstabelecimento: true })
const patch = (ie_valor, ds_mnemonico, dataSend) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    return axios.patch(`${ configs.baseUrl }/medclub/dominios/${ds_mnemonico}/valores/${ie_valor}/`, dataSend, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } })
        .then(response => response)
        .catch(configs.errorHandler)
}
const alterarStatus = (ie_valor, ds_mnemonico, dataSend) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    return axios.patch(`${ configs.baseUrl }/medclub/dominios/${ds_mnemonico}/valores/${ie_valor}/`, dataSend, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } })
        .then(response => response)
        .catch(configs.errorHandler)
}
export default {
    find,
    findAll,
    save,
    patch,
    alterarStatus
}
