import axios from 'axios'
import configs from '../configs'
import GenericDAO from './../generic-dao'

const failed = new Promise(resolve => { resolve(false) })

const find = (id) => GenericDAO.find(`/webcli/especialista/${ id }/`)
const findAll = (params) => GenericDAO.find(`/webcli/especialista/`, params)
const findAllClean = (params) => GenericDAO.find(`/webcli/especialista-limpo/`, params, { ignorarEstabelecimento: true })
const save = (dataSend) => GenericDAO.save(`/webcli/especialista/`, dataSend, {formDataIgnore: true})
const remove = (id) => GenericDAO.remove(`/webcli/especialista/${ id }/`)
const addUsuario = (idEspecialista, username) => GenericDAO.save(`/webcli/especialista/add-usuario/`,
    { cd_especialista: idEspecialista, username })
const removeUsuario = (idEspecialista) => GenericDAO.remove(`/webcli/especialista/add-usuario/`,
    null, { 'cd_especialista': idEspecialista })
const getEstabelecimentos = () => GenericDAO.find(`/webcli/especialista/estabelecimentos/`)
const getAll = () => GenericDAO.find(`/appuse/fluxo-dois/especialista/`)
const getAllByParams = (params, configs) => GenericDAO.find(`/appuse/fluxo-dois/especialista/`, params, configs)
const getEspecialidades = (idEspecialista) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else {
        return axios.get(`${ configs.baseUrl }/webcli/especialista/especialidade/?cd_especialista=${ idEspecialista }`,
            { headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
            } }
        )
        .then(response => response)
        .catch(configs.errorHandler)
    }
}

const findByEstabelecimento = (cdEstabelecimento, params) => {
    if (! params) params = {}
    params.cd_estabelecimento = cdEstabelecimento
    return GenericDAO.find(`/webcli/especialista/`, params, { ignorarEstabelecimento: true })
}

export default {
    find,
    save,
    remove,
    findAll,
	findAllClean,
    addUsuario,
    getAll,
    removeUsuario,
    getEspecialidades,
    getEstabelecimentos,
    findByEstabelecimento,
    getAllByParams
}
