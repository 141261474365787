import axios from 'axios'
import configs from '../configs'

const failed = new Promise(resolve => { resolve(false) })

const getUnidades = (params) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.get(
        `${ configs.baseUrl }/medclub/integracao/get_unidades/`, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        }, params:params }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}
const getEspecialidades = (params) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.get(
        `${ configs.baseUrl }/medclub/integracao/get_especialidades/`, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        }, params:params }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}
const getAgendasConsulta = (params) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.get(
        `${ configs.baseUrl }/medclub/integracao/get_agendas_consulta/`, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`,
        }, params:params }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const getAgendasExames = (params) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else return axios.get(
        `${ configs.baseUrl }/medclub/integracao/get_agendas_exames/`, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        }, params:params }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}


export default {
    getUnidades,
    getEspecialidades,
    getAgendasConsulta,
    getAgendasExames,
}
