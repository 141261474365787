import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/webcli/paciente/`, params)
const find = (id, params) => GenericDAO.find(`/webcli/paciente/${ id }/`, params)
const remove = (id) => GenericDAO.remove(`/webcli/paciente/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/webcli/paciente/`, dataSend)


const imprimirBoasVindas = (idPessoaFisica) => GenericDAO.find(`/webcli/paciente/${ idPessoaFisica }/boas-vindas/`, null, { ignorarEstabelecimento: true });
const imprimirCarteira = (idPessoaFisica) => GenericDAO.find(`/medclub/imprimir-cartao/${ idPessoaFisica }/`, null, { ignorarEstabelecimento: true })
const saldoPaciente = (idPessoaFisica, params) => GenericDAO.find(`/webcli/paciente/${ idPessoaFisica }/saldo-creditos/`, params, { ignorarEstabelecimento: true})
const findAgendamentos = (idPessoaFisica, params) => GenericDAO.find(`/webcli/paciente/${idPessoaFisica}/agendamentos/`, params, {ignorarEstabelecimento: true})
const findFamilia = (idPessoaFisica) => GenericDAO.find(`/webcli/familia-paciente/${ idPessoaFisica }/`, null, { ignorarEstabelecimento: true })

export default {
    find,
    findAll,
    remove,
    save,
    imprimirCarteira,
	imprimirBoasVindas,
    saldoPaciente,
    findFamilia,
    findAgendamentos
}
