import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/webcli/empresa/`, params)
const find = (id) => GenericDAO.find(`/webcli/empresa/${ id }/`)
const remove = (id) => GenericDAO.remove(`/webcli/empresa/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/webcli/empresa/`, dataSend)

const toggle_status = (id, dataSend) => GenericDAO.save(`/webcli/empresa/${ id }/status/`, dataSend)
const adicionarCorrentista = (dataSend) => GenericDAO.save('/webcli/empresa/credenciar-correntista/', dataSend)
const excluirCorrentista = (dataSend) => GenericDAO.save(`/webcli/empresa/descredenciar-correntistas/`, dataSend)

const saveUsuario = (dataSend) => GenericDAO.save(`/webcli/empresa/usuarios/`, dataSend)

const salvarRendimento = (dataSend) => GenericDAO.save(`/webcli/empresa/rendimento/`, dataSend)
const getRendimento = (id, params) => GenericDAO.find(`/webcli/empresa/rendimento/${ id }/`, params)
const statusAtualizacaoRendimento = (dataSend) => GenericDAO.save(`/webcli/empresa/rendimento/atualizacao/`, dataSend)

const listRevendedores = (dataSend) => GenericDAO.find(`/webcli/empresa/usuarios/`, dataSend)
const associarUsuario = (dataSend) => GenericDAO.save('/webcli/empresa/associar-cliente/', dataSend, { ignorarEstabelecimento: true })
const removerAssociado = (dataSend) => GenericDAO.save(`/webcli/empresa/remover-cliente/`, dataSend)
const listarAssociados = (params) => GenericDAO.find(`/webcli/empresa/listar-clientes/`, params)
const historicoAssociado = (params) => GenericDAO.find(`/webcli/empresa/historico-correntista/`, params)

const addCreditos = (dataSend) => GenericDAO.save('/webcli/empresa/medcred/comprar/', dataSend)
const getSaldo = (params) => GenericDAO.find('/webcli/empresa/medcred/saldo/', params)

const getAgenda = (params) => GenericDAO.find('/webcli/empresa/agendamentos/', params)
const getExtrato = (params) => GenericDAO.find('/webcli/empresa/medcred/extrato/', params)
const pagarServicos = (dataSend) => GenericDAO.save('/medclub/empresa/caixa/', dataSend)
const getDados = (params) => GenericDAO.find('/webcli/empresa/dados/', params)

const getTiposEmpresa = (params) => GenericDAO.find('/webcli/tipo-empresa/', params)

const getUtilizacoes = (id, params) => GenericDAO.find(`/webcli/empresa/rendimento-utilizacoes/${ id }/`, params)

export default {
    find,
    findAll,
    remove,
    save,
    listRevendedores,
    saveUsuario,
    pagarServicos,
    associarUsuario,
    getExtrato,
    addCreditos,
    getAgenda,
    getSaldo,
    listarAssociados,
    removerAssociado,
    getDados,
    getTiposEmpresa,
    toggle_status,
    salvarRendimento,
    getRendimento,
    statusAtualizacaoRendimento,
    excluirCorrentista,
    adicionarCorrentista,
    getUtilizacoes,
    historicoAssociado,
}
